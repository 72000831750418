<template>
	<!-- flex_column -->
	<div class="aircraftBrand_page" style="height: 100%">
		<!-- 飞机库组件 -->
		<hangar :title="$t('customerService.name')" :isPhone="isPhone"></hangar>
		
		<div class="contactUS_page" style="padding: 20px">
			<div class="center">
				<div v-if="buttonList.length > 0" class="zui-flex-row" :class="language=='en'?'to-wrap':''">
					<el-button
						size="mini"
						v-for="(item, index) in buttonList"
						:key="index"
						:class="item.id == curBtn ? 'btnColor act' : 'btnColor'"
						plain
						@click="changeIndex(item.id)"
					>
						{{ item.name }}
					</el-button>
				</div>
			</div>
		</div>
		<!-- 返回按钮 -->
		<div id="back" v-show="isPhone" @click="back"
			style="z-index: 999; position: fixed;top: 50%;left: 0;width: 50px;height: 50px;border-radius: 50%;">
			<img src="../assets/image/back_phone.png" style="height: 100%;width: 100%;">
		</div>
	</div>
</template>

<script>
import hangar from '../components/hangarCom';
import { mapState } from 'vuex';
// 飞机品牌页面
export default {
	data() {
		return {
			
			buttonList: [
				{
					name: this.$t('customerService.tab1'),
					id: '0',
					url: 'trading'
				},
				{
					name: this.$t('customerService.tab2'),
					id: '1',
					url: 'report'
				}
			],

			curBtn: -1
		};
	},
	computed: {
		...mapState(['userInfo', 'isLogin','language'])
	},
	watch: {
		language() {
			this.buttonList=[
				{
					name: this.$t('customerService.tab1'),
					id: '0',
					url: 'trading'
				},
				{
					name: this.$t('customerService.tab2'),
					id: '1',
					url: 'report'
				}
			]
		},
		$route(from) {
			// console.log(from.params);
			// 监听路由变化, 实现类似 小程序的 onShow 事件
			if (from.params.id) {
				this.curBtn = from.params.id - 1;
			} else {
				this.curBtn = 0;
			}
		}
	},
	created: function() {
		if (/Android|webOS|iPhone|iPad|BlackBerry/i.test(navigator.userAgent)) {
			this.isPhone = true
		} else {
			this.isPhone = false
		}
	},
	mounted() {
	},
	methods: {
		changeIndex(e) {
			this.curBtn = e;
			if (!this.isLogin) {
				this.$router.push({ name: 'login' });
			} else {
				switch (Number(e)) {
					case 0:
						if (this.userInfo.userAuthority) {
							let isShow = this.userInfo.userAuthority.indexOf('process');
							if (isShow == -1) {
								this.showMsg();
							} else {
								this.$router.push({ name: this.buttonList[e].url });
							}
						} else {
							this.showMsg();
						}
						break;
					case 1:
						if (this.userInfo.userAuthority) {
							let isShow = this.userInfo.userAuthority.indexOf('hosting');
							if (isShow == -1) {
								this.showMsg();
							} else {
								this.$router.push({ name: this.buttonList[e].url });
							}
						} else {
							this.showMsg();
						}
						break;
				}
			}
		},
		showMsg() {
			this.$message({
				message: this.$t('customerService.mesg'),
				showClose: true,
				type: 'error',
				customClass: 'hahaha',
				duration: 2000,
				offset: 20
			});
		}
	},
	components: {
		hangar
	}
};
</script>

<style lang="scss" scoped>
@import '../assets/scss/config.scss';
@import '../assets/scss/mixin.scss';



.contactUS_page {
	width: 100%;
	height: calc(100% - 130px);
	background-image: url('../assets/image/14.jpg');
	background-position: top;
	background-size: cover;
	background-repeat: no-repeat;
	background-color: rgba($color: #000000, $alpha: 1);
	display: flex;
	flex-direction: column;
	align-items: center;

	// justify-content: flex-end;
	.center {
		min-height: 300px;
		margin: 20px 0;
		background-color: rgba($color: #ffffff, $alpha: 0.5);
		width: 40%;
		display: flex;
		justify-content: center;
		box-sizing: border-box;
		padding: 30px;
		box-shadow: 3px 5px 5px rgba(0, 0, 0, 0.3);
		.btnColor {
			// width: 147px;
			height: 32px;
			background: transparent;
			border: 1px solid black;
			border-radius: 4px;
			color: black;
			
			text-transform: lowercase;
			font-variant: small-caps;
			// padding: 4px 6px;
		}
		.act {
			background-color: $colorJ;
			color: $colorG;
		}
		.el-button.is-plain:focus,
		.el-button.is-plain:hover {
			background: #dbb96e;
			border-color: #dab96b;
			color: white;
		}
	}
}
@media screen and (max-width: 1440px) {
	.to-wrap{
		flex-direction: column;
		align-items: center;
		.btnColor{
			width: 100%;
			margin-top: 10px;
		}
		.el-button+.el-button{
			margin-left: 0;
		}
	}
}
// 手机适配
@media screen and (max-width: 960px) {
	
	.contactUS_page {
		.center {
			padding-top: 30px !important;
			padding: 0;
			margin: 0;
			width: 100%;
			.btnColor {
				padding: 4px 6px;
			}
		}
	}
}
</style>
